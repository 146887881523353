import "./src/styles/global.css";
import React from "react";
import { AppProvider } from "./src/context/AppProvider";
import 'react-notifications-component/dist/theme.css'
import { Toaster } from "react-hot-toast";

export const wrapRootElement = ({ element }) => {

  return <AppProvider>
    <>
      <Toaster position="top-center" toastOptions={{
        // Define default options
        className: '',
        duration: 5000,
        style: {
          background: '#363636',
          color: '#fff',
        },

        // Default options for specific types
        success: {
          duration: 5000,
          theme: {
            primary: 'green',
            secondary: 'black',
          },
        },    // Default options for specific types
        error: {
          duration: 5000,
          theme: {
            primary: 'green',
            secondary: 'black',
          },
        },
      }} />
      {element}
    </>
  </AppProvider>;
};